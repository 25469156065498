<template>
  <div id="mine" style="padding-bottom: 6rem">
    <div>
      <div class="avatarAndName" @click="showEditDalog">
        <div style="float: left; width: 60px; height: 60px; border-radius: 30px; background-color: #fff">
          <van-image round width="60px" height="60px" :src="pic1" />
        </div>
        <div style="float: left; width: 60%; height: 60px; color: #fff; margin-left: 12px">
          <div style="text-align: left; height: 50px; font-size: 24px; line-height: 50px">
            {{ userName }}
            <i class="el-icon-arrow-right"></i>
          </div>
          <div style="text-align: left; color: #fff; font-size: 1.25rem">
            理糖宝已陪您度过
            <span style="color: #0f8bd7">{{ bgDays }}</span>
            个昼夜
          </div>
        </div>
      </div>
      <!-- 患者账号图标 -->
      <div
        style="
          border-radius: 1rem;
          width: 90vw;
          margin-left: 5vw;
          margin-top: 1rem;
          padding-top: 1rem;
          height: 8rem;
          background: #fff;
        "
      >
        <el-row>
          <el-col :span="6">
            <div @click="showCode(2)">
              <img :src="dzbl" style="width: 5rem; height: 5rem" />
            </div>
            <div class="m-g-5">电子病历</div>
          </el-col>
          <el-col :span="6">
            <div @click="openReport()">
              <img :src="xtbg" style="width: 5rem; height: 5rem" />
            </div>
            <div class="m-g-5">血糖报告</div>
          </el-col>
          <el-col :span="6">
            <div @click="openShop">
              <img :src="zxsc" style="width: 5rem; height: 5rem" />
            </div>
            <div class="m-g-5">在线商城</div>
          </el-col>
          <el-col :span="6">
            <div @click="openCGMView">
              <img :src="wdsb" style="width: 5rem; height: 5rem" />
            </div>
            <div class="m-g-5">我的设备</div>
          </el-col>
        </el-row>
      </div>

      <div
        style="
          text-align: left;
          border-radius: 1rem;
          width: 90vw;
          margin-left: 5vw;
          margin-top: 1rem;
          padding: 1rem 0;
          background: #fff;
        "
      >
        <van-cell-group>
          <van-cell title="硅基授权码" value=">" @click="GJcode()" />
          <van-cell title="我的二维码" value=">" @click="showCode(1)" />
          <!-- <van-cell v-if="!hospital" title="我的病历" value=">" @click="showCode(2)" /> -->
          <!-- <van-cell title="病历报告" @click="openReport" value=">" /> -->
          <van-cell title="警报设置" @click="openAlarm" value=">" />
          <van-cell title="服务协议" @click="$router.push('sijoyService')" value=">" />
          <van-cell title="隐私政策" @click="$router.push('sijoyPrivacy')" value=">" />
          <van-cell title="软件更新" @click="$router.push('updateApp')" value=">" />
          <van-cell
            v-if="cgmType == 'loop' && version == 'loop'"
            title="loop远程操作"
            @click="toLoopHandle"
            value=">"
          />
          <van-cell v-if="cgmType == 'loop' && version == 'loop'" title="我的闭环血糖" @click="toMyNs()" value=">" />
          <!-- <van-cell title="切换角色" @click="chageRole" value=">" /> -->
          <!-- <van-cell title="注销账户" @click="logOff" value=">" /> -->
          <van-cell title="退出登录" @click="loginOut" value=">" />
        </van-cell-group>
      </div>
      <div style="line-height: 2rem">
        <van-popup v-model="showChageRole" round position="bottom" :style="{ height: '30%' }">
          <van-picker
            title="角色"
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="onCancel"
            @change="onChange"
          />
        </van-popup>
      </div>
    </div>
    <!-- <EditDialog
      v-if="showEditDialog"
      @goBackMineView="goBackMineView"
    ></EditDialog> -->
    <selectHospital v-if="showSelectHospital" @closeSelectHospital="closeSelectHospital"></selectHospital>
    <!-- 我的二维码 -->
    <van-dialog v-model="showQRCCOde" :title="qrcType == 1 ? '患者二维码' : '医生二维码'" confirmButtonText="关闭">
      <qrcCode style="margin: auto" :qrcType="qrcType"></qrcCode>
    </van-dialog>
    <van-dialog class="hintDialog" v-model="showHint" title="提示" show-cancel-button>
      <p>您的闭环尚未接入到糖仁云</p>
      <br />
      <p>请接入后再试</p>
    </van-dialog>
    <!-- 医院列表 -->
    <van-dialog
      v-model="hospitalDialog"
      class="hospitalDialog"
      title="选择医院"
      showConfirmButton
      confirmButtonText="关闭"
    >
      <div class="dialog-box" v-for="(item, index) in hospitalList" :key="index" @click="hospitalClick(item)">
        {{ item.struct_name }}
      </div>
    </van-dialog>
    <!-- 在线商城 -->
    <van-dialog
      v-model="shopShow"
      title="长按识别跳转商城"
      showConfirmButton
      confirmButtonText="关闭"
      theme="round-button"
      confirmButtonColor="#81cdfc"
    >
      <img src="../../static/pic/shop.jpg" />
    </van-dialog>
    <van-dialog
      v-model="jsShow"
      showConfirmButton
      confirmButtonText="关闭"
      theme="round-button"
      confirmButtonColor="#81cdfc"
    >
      <img style="width: 100%; height: 100%" src="../../static/pic/jssupport.jpg" />
    </van-dialog>
  </div>
</template>
<script>
  import ysrz from '../../static/pic/ysrz.png'
  import wdgz from '../../static/pic/wdgz.png'
  import zxsc from '../../static/pic/zxsc.png'
  import jszc from '../../static/pic/jszc.png'
  import xtbg from '../../static/pic/xtbg.png'
  import tjbg from '../../static/pic/tjbg.png'
  import wdsb from '../../static/pic/wdsb.png'
  import dzbd from '../../static/pic/dzbd.png'
  import dzbl from '../../static/pic/dzbl.png'
  import { Toast } from 'vant'
  import EditDialog from './component/editDialog.vue'
  import selectHospital from './component/selectHospital.vue'
  import qrcCode from '../personnelManagement/components/qrcCode.vue'
  import { calculate } from '../sugerData/getSugerData'
  import { getLoopOption, getHospitalByDoctor, getHospitalByPatient } from '@/api/relationship'
  import { loginOUT, loginOff } from '@/api/userServices'
  import { getStructIdByPatientId, getUrlByStructId } from '@/api/admin'
  export default {
    name: 'MineView',
    components: { EditDialog, selectHospital, qrcCode },
    data() {
      return {
        showSelectHospital: false,
        pic1: '',
        ysrz: ysrz,
        wdgz: wdgz,
        zxsc: zxsc,
        jszc: jszc,
        xtbg: xtbg,
        tjbg: tjbg,
        wdsb: wdsb,
        dzbd: dzbd,
        dzbl: dzbl,
        showEditDialog: false,
        userName: '',
        userAddr: '',
        showChageRole: false,
        columns: ['主任', '医生', '患者'],
        showQRCCOde: false,
        showHint: false,
        bgDays: '',
        hospitalDialog: false,
        hospitalList: [],
        version: localStorage.getItem('version'),
        cgmType: JSON.parse(localStorage.getItem('appuser')).cgm,
        qrcType: null,
        isCases: '',
        hospital: null,
        shopShow: false,
        jsShow: false,
      }
    },

    mounted() {
      this.hospital = localStorage.getItem('hospital')
      this.flashUserInfo()
      window.loginoutTojs = this.loginoutTojs
    },

    methods: {
      toMyNs() {
        this.$router.push('/nsPage')
      },
      GJcode() {
        this.$router.push('/GJcode')
      },
      openReport() {
        this.$router.push({
          path: '/hospitalList',
        })
        localStorage.setItem('comePath', 'report')
      },
      openPersonnel() {
        const appuser = localStorage.getItem('appuser')
        const params = {
          doctorId: JSON.parse(appuser).id,
          status: 1,
        }
        getHospitalByDoctor(params).then((res) => {
          if (res.data.data && res.data.data.length > 0) {
            this.$router.push({
              path: '/hospitalList',
            })
            localStorage.setItem('comePath', 'myPatient')
          } else {
            Toast('您未绑定医院，暂无医生权限！')
          }
        })
      },
      // loop闭环操作
      toLoopHandle() {
        const uid = JSON.parse(localStorage.getItem('appuser')).id
        getLoopOption({ uid: uid }).then((data) => {
          console.log(data)
          if (data.data) {
            localStorage.setItem('loopOptions', JSON.stringify(data.data))
            this.$router.push('/MineView/loopHandle')
          } else {
            this.showHint = true
          }
        })
      },
      // 我的二维码
      showCode(num) {
        if (num == 1) {
          // this.isCases = false;
          // const params = {
          //   doctorId: JSON.parse(localStorage.getItem("appuser")).id,
          //   status: 1,
          // };
          // getHospitalByDoctor(params).then((res) => {
          //   if (res.data.data && res.data.data.length > 0) {
          //     this.hospitalList = res.data.data;
          //     this.hospitalDialog = true;
          //     this.qrcType = 2;
          //   } else {
          //     this.showQRCCOde = true;
          //     this.qrcType = 1;
          //   }
          // });
          const structId = localStorage.getItem('hospital')
            ? JSON.parse(localStorage.getItem('hospital')).struct_id
            : null
          if (structId) {
            this.qrcType = 2
            this.showQRCCOde = true
          } else {
            this.qrcType = 1
            this.showQRCCOde = true
          }
        } else {
          this.isCases = true
          const params = {
            patientId: JSON.parse(localStorage.getItem('appuser')).id,
            status: 1,
          }
          getHospitalByPatient(params).then((res) => {
            if (res.data && res.data.data.length == 1) {
              this.$router.push({
                path: '/patientCases',
                query: {
                  structId: res.data.data[0].struct_id,
                  patientId: JSON.parse(localStorage.getItem('appuser')).id,
                  name: JSON.parse(localStorage.getItem('appuser')).name,
                  isEdit: 1,
                },
              })
            } else {
              this.hospitalList = res.data.data
              this.hospitalDialog = true
            }
          })
        }
      },
      // 选择医院
      hospitalClick(item) {
        if (this.isCases) {
          this.$router.push({
            path: '/patientCases',
            query: {
              structId: item.struct_id,
              patientId: JSON.parse(localStorage.getItem('appuser')).id,
              name: JSON.parse(localStorage.getItem('appuser')).name,
              isEdit: 1,
            },
          })
        } else {
          localStorage.setItem('hospital', JSON.stringify(item))
          this.hospitalDialog = false
          this.showQRCCOde = true
        }
      },
      openAlarm() {
        const phoneType = localStorage.getItem('phoneType')
        if (phoneType == 'Android') {
          window.android.openNotification()
        }
        this.$router.push('/BloodSugar/alarmCFG')
      },
      // structConfig () {
      //   this.$router.push('/MineView/adminMain')
      // },
      closeSelectHospital() {
        this.showSelectHospital = false
      },
      openCGMView() {
        this.$router.push('/CGMDetails')
      },
      openShop() {
        const appuser = JSON.parse(localStorage.getItem('appuser'))
        getStructIdByPatientId({ patientId: appuser.id }).then((res) => {
          if (res.data.code == 200 && res.data.data.structId) {
            let structId = res.data.data.structId

            getUrlByStructId({ structId: structId }).then((result) => {
              this.reportUrl =
                (result.data.data.shopUrl ? result.data.data.shopUrl : 'https://shop.cgm.go-sugar.cn/') +
                '?uid=' +
                appuser.id +
                '&shopHelper=' +
                result.data.data.shopHelper +
                '&apiUrl=' +
                (result.data.data.apiUrl ? result.data.data.apiUrl : 'https://shop.cgm.go-sugar.cn/shop/')

              window.parent.location.href = this.reportUrl
            })
          } else {
            this.reportUrl =
              'https://shop.cgm.go-sugar.cn/?uid=' +
              appuser.id +
              '&shopHelper=https://work.weixin.qq.com/kfid/kfc56e640a9f767f459' +
              '&apiUrl=https://shop.cgm.go-sugar.cn/shop/'

            window.parent.location.href = this.reportUrl
          }
        })
      },
      // 技术支持
      openJs() {
        this.jsShow = true
      },
      openReport() {
        Toast('血糖报告正在开发中！')
      },
      // goBackMineView() {
      //   this.showEditDialog = false;
      //   this.flashUserInfo();
      // },
      showEditDalog() {
        this.$router.push('mineEdit')
        // this.showEditDialog = true;
      },
      openEmpTree() {
        this.$router.push('/SmartTree')
      },

      // 医生认证
      openAuthentication() {
        this.$router.push('/authentication')
      },
      // 推广员
      openPromoter() {
        const appuser = localStorage.getItem('appuser')
        if (JSON.parse(appuser).role) {
          this.$router.push('/promoter')
        } else {
          Toast('您暂无推广员权限！')
        }
      },
      flashUserInfo() {
        const user = JSON.parse(localStorage.getItem('appuser'))
        // this.pic1 = user.images?user.images:'https://i.postimg.cc/2SrXwLXR/litangbao.png';
        this.pic1 =
          !user.images || user.images == '未设置' ? 'https://i.postimg.cc/2SrXwLXR/litangbao.png' : user.images
        this.userName = user.name
        this.userAddr = user.city

        const endDate = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.bgDays = calculate(user.createdate, endDate)
      },
      loginOut() {
        this.$dialog
          .confirm({
            title: '退出登录',
            message: '您确定要退出理糖宝吗?',
          })
          .then(() => {
            let phoneFlag = navigator.userAgent.match(
              /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            )
            if (phoneFlag) {
              const appuser = localStorage.getItem('appuser')
              const params = {
                id: JSON.parse(appuser).id,
              }
              loginOUT(params).then((res) => {
                if (res.data.code == 200) {
                  localStorage.removeItem('appuser')
                  localStorage.removeItem('hospital')
                  localStorage.setItem('loginStatus', false)
                  localStorage.removeItem('token', false)
                  this.$router.push('/login')

                  const message = {
                    message: '退出登录',
                    body: {},
                  }
                  const androidMessage = JSON.stringify(message)
                  const phoneType = localStorage.getItem('phoneType')
                  if (phoneType == 'Android') {
                    window.android.loginoutTojs(androidMessage)
                  } else {
                    window.webkit.messageHandlers.loginoutTojs.postMessage(message)
                  }
                }
              })
            } else {
              localStorage.removeItem('appuser')
              localStorage.removeItem('hospital')
              localStorage.setItem('loginStatus', false)
              localStorage.removeItem('token', false)
              this.$router.push('/login')

              const message = {
                message: '退出登录',
                body: {},
              }
              const androidMessage = JSON.stringify(message)
              const phoneType = localStorage.getItem('phoneType')
              if (phoneType == 'Android') {
                window.android.loginoutTojs(androidMessage)
              } else {
                window.webkit.messageHandlers.loginoutTojs.postMessage(message)
              }
            }
          })
          .catch(() => {
            // on cancel
          })
      },
      logOff() {
        this.$dialog
          .confirm({
            title: '注销账户',
            message: '您确定要注销账户吗?如需找回，请联系客服！',
          })
          .then(() => {
            const appuser = localStorage.getItem('appuser')
            const params = {
              uid: JSON.parse(appuser).id,
            }
            loginOff(params).then((res) => {
              if (res.data.code == 200) {
                localStorage.removeItem('appuser')
                localStorage.removeItem('hospital')
                localStorage.setItem('loginStatus', false)
                localStorage.removeItem('token', false)
                this.$router.push('/login')
              }
            })
          })
          .catch(() => {
            // on cancel
          })
      },
      chageRole() {
        this.showChageRole = true
      },
      onConfirm(value, index) {
        Toast(`当前值：${value}, 当前索引：${index}`)
        if (index === 1) {
          this.showChageRole = false
          this.showSelectHospital = true
        }
      },
      onChange(picker, value, index) {
        Toast(`当前值：${value}, 当前索引：${index}`)
      },
      onCancel() {
        Toast('取消')
      },
    },
  }
</script>
<style scoped lang="less">
  .avatarAndName {
    padding: 1rem 0 0 3rem;
    height: 60px;
    width: 100vw;
    margin-top: 1rem;
  }
  #mine {
    width: 100%;
    height: 91vh;
    overflow: hidden;
    background: -webkit-linear-gradient(#7bcbfc, #fff); /* Safari 5.1 - 6.0 */

    background: -o-linear-gradient(#7bcbfc, #fff); /* Opera 11.1 - 12.0 */

    background: -moz-linear-gradient(#7bcbfc, #fff); /* Firefox 3.6 - 15 */

    background: linear-gradient(#7bcbfc, #fff); /* 标准的语法（必须放在最后） */
  }
  ::v-deep .hintDialog {
    .van-dialog__header {
      margin-bottom: 10px;
    }
    .van-dialog__content {
      height: 40px;
      p {
        margin: 0;
      }
    }
  }
  ::v-deep .hospitalDialog {
    .van-dialog__header {
      padding-top: 10px;
      font-weight: 700;
    }
  }
  .dialog-box {
    align-items: center;
    background-color: #dbe3f8;
    border-bottom: 3px solid #b0bee4;
    border-radius: 0.375rem;
    color: #6372a5;
    display: flex;
    font-size: 1.125rem;
    font-weight: 600;
    height: 3rem;
    justify-content: center;
    overflow: hidden;
    padding: 0 1rem;
    text-decoration: none;
    transition: 0.2s ease-in-out;
    transition-property: background-color, box-shadow, border;
    width: 70%;
    cursor: pointer;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .dialog-box:hover {
    background-color: #b0bee4;
    border-color: #98a7d4;
    color: #485584;
    outline: none;
  }
  .m-g-5 {
    margin-top: 5px;
  }
  ::v-deep .van-image--round {
    img {
      object-fit: contain;
    }
  }
</style>
